<template>
  <notice-detail :id="id"/>
</template>
<script>
import NoticeDetail from '@/components/NoticeDetail.vue'

export default {
  name: 'banner',
  components: {
    NoticeDetail
  },
  data() {
    return {
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {}

}
</script>

<style>
</style>